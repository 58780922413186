import { LandingItem } from "@/interfaces/landingItem";
import { Component, Vue } from "vue-property-decorator";
import Template from "./RetailLanding.template.vue";

@Component({
  mixins: [Template],
  components: {
    LandingComponent: () =>
      import("@/components/sharedComponents/landing/landing.component")
  }
})
export default class RetailLandingComponent extends Vue {
  public landingItems: LandingItem[] = [
    {
      translationString: "check_in",
      namedRoute: "check-in"
    },
    {
      translationString: "point_of_sale",
      namedRoute: "point-of-sale"
    },
    {
      translationString: "customer.customers_management",
      namedRoute: "customers-management"
    },
    {
      translationString: "retail_sales_history",
      namedRoute: "sales-history"
    },
    {
      translationString: "retail_options",
      namedRoute: "retail-options"
    }
  ];
}
